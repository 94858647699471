<template>
  <div class="center">
    <AppDropdownSelect :options="options" :autoHide=true :showArrow=false :selectable=false placement="bottom"
      popoverClass="more-custom-popover" @input="onItemClick">
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>
import AppDropdownSelect from '@/components/common/app-dropdown-select/AppDropdownSelect'

export default {
  components: {
    AppDropdownSelect
  },

  props: ['row', 'nested', 'xprops'],

  data() {
    return {
      options: [
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit' },
      ],
    }
  },

  mounted() {
    if (this.row.is_tollfree) {
      this.options.push({ icon: 'fa fa-check', label: 'Verify', value: 'verify' });
    }
  },

  methods: {
    onItemClick(item) {
      switch (item.value) {
        case 'edit':
          this.$router.push({ name: 'business.numbers.edit', params: { id: this.row.id } })
          break;
        case 'verify':
          this.$router.push({ name: 'business.numbers.verification', params: { id: this.row.id } })
          break;
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>